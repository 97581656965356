<template>
  <div class="d-flex d-inline-flex align-items-center" style="width: 100%">
    <div class="divBackArrow d-flex align-items-center">
      <img src="img/svg/chevron-left-solid.svg" alt="" />
    </div>
    <div
      class="d-flex d-inline-flex align-items-center mx-2 cs-profile"
      style="width: 100%"
      @click="expandProfile()"
    >
      <img src="rw-staff.png" class="m-1" style="width: 32px; height: 32px" />
      <div class="mx-2">
        <div class="font-weight-bold">
          John Doe <br />
          <small style="color: rgba(255, 255, 255, 0.7)"
            >Anak Magang Di Rumahweb</small
          >
        </div>
      </div>
      <!-- <div class="text-muted">
        <small><i class="fa fa-quote-right"></i> Duit adalah jalan ninjaku</small>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "AfterLogin",
};
</script>

<style scoped></style>
