<template>
  <div class="conversation">
    <div
      v-for="(msg, key) in messages"
      class="rw-chat"
      :class="msg.actor === 'customer' ? 'rw-chat-right' : ''"
      :key="key"
    >
      <!-- keperluan boot rw ex. tipe : alert, chooseDepartement-->
      <template v-if="msg.actor === 'system'">
        <!-- alert -->
        <div v-if="msg.tipe === 'alert'">
          <small class="text-muted">
            <img
              src="img/svg/volume-up-solid.svg"
              alt="alert"
              style="width: 14px"
            />
            &nbsp;
            <i v-html="msg.message"></i>
          </small>
        </div>

        <!-- choose departement -->
        <div v-if="msg.tipe === 'chooseDepartement'">
          <div class="float-left text-center">
            <img
              class="mt-2"
              :src="msg.image"
              :alt="msg.name"
              :title="msg.name"
              style="height: 30px; border-radius: 50%"
            />
            <div>
              <small>{{ msg.name }}</small>
            </div>
          </div>
          <div class="rw-chat-text">
            <h6>Pilih departement :</h6>
            <a class="badge badge-info" @click="chooseDepartement('sales')"
              >Sales</a
            >
            &nbsp;
            <a class="badge badge-success" @click="chooseDepartement('billing')"
              >Billing</a
            >
            &nbsp;
            <a class="badge badge-danger" @click="chooseDepartement('teknis')"
              >Technical Support</a
            >
          </div>
        </div>

        <!-- rating staf -->
        <div v-if="msg.tipe === 'rating'">
          <div class="float-left text-center">
            <img
              class="mt-2"
              :src="msg.image"
              :alt="msg.name"
              :title="msg.name"
              style="height: 30px; border-radius: 50%"
            />
            <div>
              <small>{{ msg.name }}</small>
            </div>
          </div>
          <div class="rw-chat-text">
            <div class="rating">
              <input type="radio" v-model="form.ratingValue" value="5" id="5" />
              <label for="5">☆</label>
              <input type="radio" v-model="form.ratingValue" value="4" id="4" />
              <label for="4">☆</label>
              <input type="radio" v-model="form.ratingValue" value="3" id="3" />
              <label for="3">☆</label>
              <input type="radio" v-model="form.ratingValue" value="2" id="2" />
              <label for="2">☆</label>
              <input type="radio" v-model="form.ratingValue" value="1" id="1" />
              <label for="1">☆</label>
            </div>
            <textarea
              @keyup.enter="sendRating()"
              class="form-control"
              v-model="form.ratingMessage"
              placeholder="Tell us something..."
            ></textarea>
          </div>
        </div>
      </template>

      <!-- keperluan message reguler -->
      <template v-else>
        <!-- for staff -->
        <div class="float-left text-center" v-if="msg.actor === 'staff'">
          <img
            class="rw-chat-img mt-2"
            src="img/rw/staff.png"
            :alt="msg.name"
            :title="msg.name"
          />
          <div>
            <small>{{ getFirstName(msg.name) }}</small>
          </div>
        </div>

        <!-- for customer -->
        <img
          v-else
          class="rw-chat-img mt-2"
          src="img/rw/customer.png"
          :alt="msg.name"
          :title="msg.name"
        />
        <div class="rw-chat-text">
          <div v-html="msg.message" class="float-left"></div>
          <div class="float-right">
            <small>{{ mixEpochToHours(msg.time) }}</small>
          </div>
          <div style="clear: both"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import "@/assets/css/customer/rw-chat.css";
import "@/assets/css/customer/rw-rating.css";
import { waktu } from "@/common/mixins/mix_helper";

export default {
  name: "Conversation",
  mixins: [waktu],
  props: {
    data: {
      required: true,
    },
    messages: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        ratingValue: "",
        ratingMessage: "",
      },
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * memilih departement untuk melanjutkan staff
     * nama akan dikirim / $emit ke parent untuk diproses
     * --------------------------------------------------------------
     */
    chooseDepartement: function (name) {
      if (name) {
        this.$emit("chooseDepartement", name);
      } else {
        console.error("error");
      }
    },
    getFirstName: function (fullName) {
      let uname = fullName.split(" ");
      return uname[0];
    },

    /**
     * --------------------------------------------------------------
     * kirim rating
     * --------------------------------------------------------------
     */
    sendRating: function () {
      if (this.form.ratingValue !== "") {
        this.$swal(
          "Success",
          `Rating Anda ${this.form.ratingValue}`,
          "success"
        );
        this.form.ratingMessage = "";
      } else {
        this.$swal("Warning", "Anda belum menentukan rating", "warning");
      }
    },
  },
};
</script>

<style scoped>
a.badge {
  cursor: pointer;
}
.conversation {
  line-height: 1.3;
  font-size: 14px;
}
</style>
