<template>
  <div id="rw-content">
    <!-- rw title -->
    <div class="rw-header">
      <cust-header :isLogin="getIsLogin"></cust-header>
    </div>

    <div class="rw-body">
      <cust-body :isLogin="getIsLogin"></cust-body>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/customer/customer.css";
import { mapGetters } from "vuex";
import { rwCookies } from "@/common/mixins/mix_cookies";

// component children
import custHeader from "@/components/customer/header/HeaderBase";
import custBody from "@/components/customer/body/BodyBase";

export default {
  name: "Customer",
  mixins: [rwCookies],
  components: {
    custHeader,
    custBody,
  },
  data() {
    return {
      // isLogin: false,
    };
  },
  computed: {
    ...mapGetters(["getIsLogin"]),
  },
  mounted() {
    let isLogin = this.cookies.get("isLogin");
    if (typeof isLogin !== "undefined") {
      this.$store.dispatch("customerLogin", isLogin);
    } else {
      this.$store.dispatch("customerLogin", isLogin);
    }
  },
};
</script>

<style scoped>
#rw-content {
  line-height: 1em;
}
</style>
