<template>
  <!-- textarea for chat-->
  <div class="d-inline-flex" style="position: absolute; bottom: 0; width: 100%">
    <TwemojiTextarea
      :emojiData="emojiDataAll"
      :emojiGroups="emojiGroups"
      :enableSendBtn="false"
      @enterKey="onEnterKey"
      @contentChangedHtml="contentChanged"
      class="flex-grow-1"
      placeholder="Write something..."
    >
    </TwemojiTextarea>
    <div class="p-2" style="background: #f7f7f7">
      <span class="hiddenFileInput">
        <input type="file" @change="onChooseFile" />
      </span>
      <!-- <i class="fa fa-paper-plane text-info  p-2"></i> -->
    </div>
  </div>
</template>

<script>
// emoji
import { TwemojiTextarea } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiAllData from "@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";

// additional
import $ from "jquery";

export default {
  name: "sendMessage",
  components: {
    TwemojiTextarea,
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * trigger ke model message karna textarea nya menggunakn 3th part
     * --------------------------------------------------------------
     */
    contentChanged(message) {
      this.message = message;
    },

    /**
     * --------------------------------------------------------------
     * when enter key was clicked by client
     * --------------------------------------------------------------
     */
    onEnterKey() {
      this.sendMessage();
    },

    /**
     * --------------------------------------------------------------
     * send message
     * --------------------------------------------------------------
     */
    sendMessage: function () {
      if (this.message.trim() !== "") {
        this.$store.dispatch("customerSendMessage", {
          tipe: "text",
          message: this.message,
        });

        // delay karna tidak bisa menyesuaikan dom
        setTimeout(() => {
          this.scrollToEndChat();

          // reset msg
          $("#twemoji-textarea").html("");
          this.message = "";
        }, 600);
      } else {
        this.$swal("error", "Message is required", "error");
      }
    },

    /**
     * --------------------------------------------------------------
     * ketika memilih file validasi ekstensi
     * --------------------------------------------------------------
     */
    onChooseFile: function (e) {
      let image = e.target.files[0];
      let allowedExt = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/bmp",
      ];

      if (allowedExt.includes(image.type)) {
        let size = Math.round(image.size / 1024);
        if (size > 2048) {
          this.$swal({
            title: "Error",
            html: `Ukuran file maksimal 2MB!`,
            icon: "error",
          });
        } else {
          let msg = URL.createObjectURL(image);
          msg = `<img src="${msg}" class="img-fluid">`;
          msg = this.mixGenerateMessage.human("Naruto Uzumaki", msg, false);
          this.messages.push(msg);

          setTimeout(() => {
            this.scrollToEndChat();

            msg = this.mixGenerateMessage.human(
              "Addin RW",
              `Anda mengirim gambar ${image.type} diatas`,
              true
            );
            this.messages.push(msg);
            setTimeout(() => {
              this.scrollToEndChat();
            }, 800);
          }, 600);
        }
      } else {
        // alert when fail
        let ext = image.type.split("/");
        ext = ext[1].split("."); // get last ext
        this.$swal({
          title: "Error",
          html: `<b>${ext[ext.length - 1]}</b> tidak diizinkan! <br> \
	  			<small><b>PNG | JPEG | JPG | GIF | BMP </b></small>`,
          icon: "error",
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * to scroll ed of element chat, must be use timeout
     * tidak dapat manipulasi dom langsung
     * --------------------------------------------------------------
     */
    scrollToEndChat: function () {
      let el = $("#chat");
      el.scrollTop(parseInt(el[0].scrollHeight));
    },
  },
  computed: {
    emojiDataAll() {
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    },
  },
};
</script>

<style scoped></style>
