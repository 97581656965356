<template>
  <div
    id="chat"
    class="container"
    style="height: 19.7em; overflow: hidden auto"
  >
    <Conversation
      :data="form"
      :messages="messages"
      @chooseDepartement="onChooseDepartement"
    ></Conversation>
  </div>
</template>

<script>
import Conversation from "@/components/customer/body/Conversation";
import { refMessages } from "@/common/firebase/init";
import { rwCookies } from "@/common/mixins/mix_cookies";
import $ from "jquery";

export default {
  name: "AfterLoginBody",
  mixins: [rwCookies],
  components: {
    Conversation,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        question: "",
        departement: "sales",
      },
      messages: [],
      messagesOld: [
        {
          name: "Naruto Uzumaki",
          time: "23 Jan 12:05",
          image: "img/rw/customer.png",
          boot: false,
          tipe: "msg",
          fromMe: false,
          message:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          name: "John Doe",
          time: "23 Jan 15:37",
          image: "img/rw/staff.png",
          bot: false,
          tipe: "message",
          fromMe: true,
          message: `Naik naik ke puncak gunung <img class="emoji" draggable="false" alt="😂" src="https://twemoji.maxcdn.com/2/72x72/1f602.png">`,
        },
        {
          name: "Bot",
          time: "23 Jan 12:05",
          image: null,
          bot: true,
          tipe: "alert",
          fromMe: null,
          message: "John Doe meninggalkan percakapan",
        },
        {
          name: "Naruto Uzumaki",
          time: "23 Jan 12:05",
          image: "img/rw/customer.png",
          bot: false,
          fromMe: false,
          tipe: "message",
          message:
            "Mendaki gunung lewati lembah sungai mengalir indah ke samudra",
        },
        {
          name: "Bot",
          time: "23 Jan 15:37",
          image: "img/rw/rw.png",
          bot: true,
          tipe: "chooseDepartement",
          fromMe: true,
          message: null,
        },
        {
          name: "Bot",
          time: "3 March 17:00",
          image: "img/rw/rw.png",
          bot: true,
          tipe: "rating",
          fromMe: true,
          messages: null,
        },
      ],
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * emit from child pilih departemen
     * --------------------------------------------------------------
     */
    onChooseDepartement: function (depName) {
      let msg = this.mixGenerateMessage.bot(
        "alert",
        `Anda sedang dihubungkan dengan <b>${depName}</b>, mohon ditunggu`
      );
      this.messages.push(msg);

      // delay karna tidak bisa menyesuaikan dom
      setTimeout(() => {
        this.scrollToEndChat();
        msg = this.mixGenerateMessage.bot(
          "alert",
          `Terhubung dengan <b>Addin RW</b> departement <b>${depName}</b> jabatan <b>Junior ${depName}</b>`
        );
        this.messages.push(msg);

        setTimeout(() => {
          this.scrollToEndChat();
          msg = this.mixGenerateMessage.human(
            "Addin RW",
            `Halo, dengan bagian ${depName} ada yang bisa dibantu ?`,
            true
          );
          this.messages.push(msg);

          setTimeout(() => {
            this.scrollToEndChat();
          }, 1000);
        }, 1000);
      }, 600);
    },

    /**
     * --------------------------------------------------------------
     * to scroll ed of element chat, must be use timeout
     * tidak dapat manipulasi dom langsung
     * --------------------------------------------------------------
     */
    scrollToEndChat: function () {
      let el = $("#chat");
      el.scrollTop(parseInt(el[0].scrollHeight));
    },
  },
  created() {
    let cookies = this.cookies.get();
    let customerId = cookies.customer.id;
    console.warn(customerId);

    refMessages.on("child_added", (items) => {
      let item = items.val();
      if (item.id === customerId || item.actor !== "customer") {
        this.messages.push(items.val());
      }
      setTimeout(() => {
        this.scrollToEndChat();
      }, 700);
      console.warn("message added", items.val());
    });
  },
};
</script>
