<template>
  <div class="container my-3">
    <p class="alert alert-primary text-center" style="line-height: 1.5">
      To help us serve you better, please provide some information before we
      begin your chat.
    </p>

    <form @submit.stop.prevent="onSubmit">
      <div class="form-group">
        <select class="form-control" v-model="form.departement">
          <option value="sales">Sales - Offline</option>
          <option value="billing">Billing - Online</option>
          <option value="technical">Technical Support - Offline</option>
        </select>
      </div>

      <!-- name field -->
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Masukan nama"
          v-model.trim="$v.form.name.$model"
        />
        <div class="error" v-if="$v.form.name.$error && !$v.form.name.required">
          Nama tidak boleh kosong
        </div>
        <div
          class="error"
          v-if="$v.form.name.$error && !$v.form.name.minLength"
        >
          Nama minimal {{ minLength("name") }} karakter
        </div>
      </div>

      <!-- email field -->
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Masukan email"
          v-model.trim="$v.form.email.$model"
        />
        <div
          class="error"
          v-if="$v.form.email.$error && !$v.form.email.required"
        >
          Email tidak boleh kosong
        </div>
        <div
          class="error"
          v-if="$v.form.email.$error && !$v.form.email.minLength"
        >
          Email minimal {{ minLength("email") }} karakter
        </div>
        <div class="error" v-if="$v.form.email.$error && !$v.form.email.email">
          Email tidak valid
        </div>
      </div>

      <!-- question field -->
      <div class="form-group">
        <textarea
          class="form-control"
          placeholder="Masukan pertanyaan"
          v-model.trim="$v.form.question.$model"
        ></textarea>
        <div
          class="error"
          v-if="$v.form.question.$error && !$v.form.question.required"
        >
          Pertanyaan tidak boleh kosong
        </div>
        <div
          class="error"
          v-if="$v.form.question.$error && !$v.form.question.minLength"
        >
          Pertanyaan minimal {{ minLength("question") }} karakter
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { loader } from "@/common/mixins/mix_loader";
import { rwCookies } from "@/common/mixins/mix_cookies";
import { refCustomer } from "@/common/firebase/init";

export default {
  name: "BeforeLogin",
  mixins: [validationMixin, loader, rwCookies],
  data() {
    return {
      form: {
        question: "",
        departement: "sales",
        name: "",
        email: "",
      },
      isLogin: "",
    };
  },
  validations: {
    form: {
      question: {
        required,
        minLength: minLength(10),
      },
      departement: {
        required,
      },
      name: {
        required,
        minLength: minLength(6),
      },
      email: {
        required,
        email,
        minLength: minLength(10),
      },
    },
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * register client
     * --------------------------------------------------------------
     */
    onSubmit: function () {
      //Cookies.set("isLogin", true);
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.loading();

      setTimeout(() => {
        this.$store
          .dispatch("customerRegister", {
            name: this.form.name,
            email: this.form.email,
          })
          .then((id) => {
            this.cookies.add({
              customer: {
                id: id,
                name: this.form.name,
                email: this.form.email,
              },
              isLogin: true,
            });

            // send 1st message
            this.$store
              .dispatch("customerSendMessage", {
                tipe: "text",
                message: this.form.question,
              })
              .then((msgid) => {
                console.warn("msgid", msgid);
                this.cookies.add({ readMsg: { id: msgid } });
              })
              .finally(() => {
                this.$store.dispatch("customerLogin", true);
                this.loading(false);
              });
          })
          .catch((err) => {
            console.warn("res error", err);
          });

        // localStorage.setItem("isLogin", true);
      }, 2000);
    },
    minLength: function (field) {
      return this.$v.form[field].$params.minLength.min;
    },
  },
  created() {
    refCustomer.on("value", (items) => {
      console.log("fb update1", items.val());
    });
  },
};
</script>

<style scoped>
.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.success {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
</style>
