<template>
  <div>
    <before-login v-if="!isLogin"></before-login>
    <after-login v-else></after-login>

    <!-- textarea send message -->
    <panel-send-message v-if="isLogin"></panel-send-message>
  </div>
</template>

<script>
import AfterLogin from "@/components/customer/body/AfterLogin";
import BeforeLogin from "@/components/customer/body/BeforeLogin";
import PanelSendMessage from "@/components/customer/sendMessage";

export default {
  name: "BodyBase",
  props: {
    isLogin: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    AfterLogin,
    BeforeLogin,
    PanelSendMessage,
  },
};
</script>
